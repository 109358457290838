<template>
  <router-view/>
</template>
<style lang="scss">

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  cursor: pointer;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/**
 * 滚动条样式
 */

::-webkit-scrollbar {
  width: 0.03rem;
  height: 1rem;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background: #d3d3d3;
  /* 浅灰色 */
  border-radius: 0.1rem;
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  /* 深灰色 */
  border-radius: 0.1rem;
  background-image: -webkit-linear-gradient(45deg,
      transparent,
      transparent,
      transparent,
      transparent);
}
</style>

